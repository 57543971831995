(function($){
	if($('.tiny-1_element').length ) {
		var slider = tns({
			container: '.tiny-1_element',
			items: 1,
			controls: false,
			mouseDrag: true,
			nav: true,
			navPosition: 'bottom',
			});
	};
	if($('.tiny_1_element-2_cols').length ) {
		var slider = tns({
			container: '.tiny_1_element-2_cols',
			items: 1,
			controls: false,
			mouseDrag: true,
			nav: true,
			navPosition: 'bottom',
			"mode": "gallery",
			"speed": 1000,
			});
	};
	
	if($('.tiny-3_elements').length ) {
		var slider = tns({
			container: '.tiny-3_elements',
			items: 1,
			controls: false,
			mouseDrag: true,
			nav: true,
			navPosition: 'bottom',
			responsive: {
				640: {
				  gutter: 20,
				  items: 2
				},
				700: {
				  gutter: 30
				},
				1024: {
				  items: 3
				}
			  }
		});
	};
	if($('.tiny_2_elements').length ) {
		var slider = tns({
			container: '.tiny_2_elements',
			items: 1,
			controls: false,
			mouseDrag: true,
			nav: true,
			navPosition: 'bottom',
			responsive: {
				640: {
				  gutter: 20,
				  items: 1
				},
				700: {
				  gutter: 30,
				  items: 2
				}
			  }
		});
	};
	if($('.ofert').length ) {
		function initOferts (){
			if(!$('.ofert').hasClass('active')) {
				$('*[data-ofert="ofert_0"]').addClass('active');
				$('#ofert_0').addClass('active');
			}
		}
		initOferts();
		$('.ofert').on('click', function(){
			if($('.ofert').hasClass('active')) {
				$('.ofert').removeClass('active');
				$('.ofert-container').removeClass('active');
			}
			$(this).addClass('active');
			$('#' + $(this).data('ofert')).addClass('active');
		});
	}

// Iterate over each select element
$('select').each(function () {

    // Cache the number of options
    var $this = $(this),
        numberOfOptions = $(this).children('option').length;

    // Hides the select element
    $this.addClass('s-hidden');

    // Wrap the select element in a div
    $this.wrap('<div class="select"></div>');

    // Insert a styled div to sit over the top of the hidden select element
    $this.after('<div class="styledSelect"></div>');

    // Cache the styled div
    var $styledSelect = $this.next('div.styledSelect');

    // Show the first select option in the styled div
    $styledSelect.text($this.children('option').eq(0).text());

    // Insert an unordered list after the styled div and also cache the list
    var $list = $('<ul />', {
        'class': 'options'
    }).insertAfter($styledSelect);

    // Insert a list item into the unordered list for each select option
    for (var i = 0; i < numberOfOptions; i++) {
        $('<li />', {
            text: $this.children('option').eq(i).text(),
            rel: $this.children('option').eq(i).val()
        }).appendTo($list);
    }

    // Cache the list items          

    var $listItems = $list.children('li');

    // Show the unordered list when the styled div is clicked (also hides it if the div is clicked again)
    $styledSelect.click(function (e) {
        e.stopPropagation();
        if($(this).hasClass('active')) {
          $(this).removeClass('active').next('ul.options').slideUp();
        } else {
          $('div.styledSelect.active').each(function () {
              $(this).removeClass('active').next('ul.options').slideUp();
          });
          $(this).toggleClass('active').next('ul.options').slideToggle();
        }
    });

    // Hides the unordered list when a list item is clicked and updates the styled div to show the selected list item
    // Updates the select element to have the value of the equivalent option
    $listItems.click(function (e) {
        e.stopPropagation();
        $styledSelect.text($(this).text()).removeClass('active');
        $this.val($(this).attr('rel'));
        $list.slideUp();
		$('.bulletins').each(function () {
			if($(this).hasClass('active')) {
				$(this).removeClass('active');
			}
		})
		$('#bulletins_' + $this.val()).addClass('active');

        /* alert($this.val()); Uncomment this for demonstration! */
    });

    // slideUps the unordered list when clicking outside of it
    $(document).click(function () {
        $styledSelect.removeClass('active');
        $list.slideUp();
    });

});
	
})(jQuery);

